.services-container {
    max-width: 968px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.services-heading {
    font-size: 2em;
    margin-bottom: 20px;
    width: 100%;
    color: #028382;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.service-card {
    border: 1px solid black;
    padding: 20px;
    text-align: center;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.service-icon {
    font-size: 2em;
    margin-bottom: 10px;
}

.service-title {
    font-size: 1.5em;
    margin: 10px 0;
}

.service-description {
    font-size: 1em;
    color: #555;
    text-align: justify;
}

@media (max-width: 768px) {
    .service-card {
        padding: 15px;
    }

    .service-title {
        font-size: 1.2em;
    }

    .service-description {
        font-size: 0.9em;
    }

    .services-heading {
        font-size: 1.8em;
    }
}

@media (max-width: 480px) {
    .service-card {
        padding: 10px;
    }

    .service-title {
        font-size: 1em;
    }

    .service-description {
        font-size: 0.8em;
    }

    .services-heading {
        font-size: 1.5em;
    }
}
