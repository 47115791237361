.footer {
    background-color: white;
    color: black;
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid black;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
}

.footer-content {
    max-width: 968px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.social-links {
    margin: 10px 0;
}

.social-links a {
    color: black;
    margin: 0 10px;
    text-decoration: none;
}

.social-links a:hover {
    text-decoration: underline;
    color:#018084 ;
    animation-duration: 1s;
}
