.team-container {
    max-width: 968px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.team-heading {
    font-size: 2em;
    margin-bottom: 20px;
    width: 100%;
    color: #028382;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.team-card {
    border: 1px solid black;
    padding: 20px;
    text-align: center;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.team-photo {
    width: 100%;
    height: auto;
    border-radius: 50%;
    margin-bottom: 10px;
}

.team-name {
    font-size: 1.5em;
    margin: 10px 0;
}

.team-role {
    font-size: 1em;
    color: #555;
}

.team-social-links {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.team-social-links a {
    color: #555;
    transition: color 0.3s;
}

.team-social-links a:hover {
    color: #028382;
}

@media (max-width: 768px) {
    .team-card {
        padding: 15px;
    }

    .team-name {
        font-size: 1.2em;
    }

    .team-role {
        font-size: 0.9em;
    }

    .team-heading {
        font-size: 1.8em;
    }
}

@media (max-width: 480px) {
    .team-card {
        padding: 10px;
    }

    .team-name {
        font-size: 1em;
    }

    .team-role {
        font-size: 0.8em;
    }

    .team-heading {
        font-size: 1.5em;
    }
}
