.contact-container {
    max-width: 968px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.contact-heading {
    font-size: 2em;
    margin-bottom: 20px;
    width: 100%;
    color:#018084 ;
}

.contact-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap; 
}

.contact-info,
.contact-form {
    flex: 1;
    min-width: 300px; 
    margin: 10px;
    padding: 20px;
    border: 1px solid black;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    
}
.contact-info{
    text-align: justify;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact-form label {
    font-size: 1em;
    text-align: left;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    font-size: 1em;
    border: 1px solid black;
    border-radius: 5px;
    box-sizing: border-box;
}

.contact-form button {
    padding: 10px;
    font-size: 1em;
    border: none;
    background-color: #038081;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.contact-form button:hover {
    background-color: #025252;
}

.status-message {
    margin-top: 10px;
    font-size: 1em;
    color: green;
}

@media (max-width: 768px) {
    .contact-heading {
        font-size: 1.8em;
    }

    .contact-content {
        flex-direction: column;
        align-items: center;
    }

    .contact-info,
    .contact-form {
        margin: 10px 0;
        width: 100%;
    }

    .contact-form label,
    .contact-form input,
    .contact-form textarea,
    .contact-form button,
    .status-message {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .contact-heading {
        font-size: 1.5em;
    }

    .contact-form label,
    .contact-form input,
    .contact-form textarea,
    .contact-form button,
    .status-message {
        font-size: 0.8em;
    }
}
