.about-container {
    max-width: 968px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
   
    background: white;
    color: black;
    
    border-radius: 10px;
    margin-bottom: 20px; 
}

.about-heading {
    font-size: 2em;
    margin-bottom: 20px;
    width: 100%;
    color: #028382;
}

.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    padding: 25px;
}

.about-logo {
    max-width: 200px;
    margin-bottom: 20px;
    border: 0.1px solid black;
    border-radius: 50%;
}

.about-text {
    text-align: justify;
    font-size: 20px;
}

@media (max-width: 768px) {
    .about-heading {
        font-size: 1.8em;
    }

    .about-text {
        font-size: 0.9em;
    }
    
}

@media (max-width: 480px) {
    .about-heading {
        font-size: 1.5em;
    }

    .about-text {
        font-size: 0.8em;
    }
}
