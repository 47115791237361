.navbar {
    position: sticky;
    top: 0;
    width: 100%;
    
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    backdrop-filter: blur(10px);
}

.navbar-content {
    max-width: 968px;
    margin: 0 auto;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
}

.navbar-logo-img {
    max-width: 80px;
    margin-right: 10px;
}

.navbar-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;
    cursor: pointer;
}

.navbar-links li {
    display: inline;
}

.navbar-links a {
    text-decoration: none;
    color:#048080;
    font-size: 1.2em;
}

.navbar-links a:hover {
    text-decoration: none;
    color:#F68722 ;
    transition: 0.7s;
}

.navbar-icons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.menu-toggle {
    cursor: pointer;
    color: rgb(0, 0, 0);
    display: none;
}

@media (max-width: 768px) {
    .navbar-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 0;
        background-color: #ffffff;
        width: 100%;
        padding: 10px;
    }

    .navbar-links.active {
        display: flex;
    }

    .menu-toggle {
        display: block;
    }
}
