.landing-page {
    position: relative;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-width: 968px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.content {
    text-align: center;
    z-index: 1;
}

.main-text {
    font-size: 4em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    color: #048080;
}

.hover-char {
    transition: color 0.3s ease;
}

.hover-char:hover {
    color:#F68722;
}

.sub-text {
    font-size: 1.2em;
    margin-top: 0.5em;
}

.scroll-indicator {
    margin-top: 2em;
    animation: moveDown 2s infinite;
    
    
 
}

@keyframes moveDown {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(10px);
    }
    60% {
        transform: translateY(5px);
    }
}

.mouse {
    width: 24px;
    height: 40px;
    border: 1px solid #f68722;
    border-radius: 12px;
    position: relative;
    display: inline-block;
}

.mouse::after {
    content: '';
    width: 4px;
    height: 8px;
    background: #000;
    border-radius: 2px;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    animation: scroll 1.5s infinite;
}

@keyframes scroll {
    0% {
        opacity: 1;
        top: 8px;
    }
    100% {
        opacity: 0;
        top: 24px;
    }
}

@media (max-width: 968px) {
    .main-text {
        font-size: 3em;
    }

    .sub-text {
        font-size: 1em;
    }

    .landing-page {
        padding: 10px;
        height: auto;
    }
}

@media (max-width: 768px) {
    .main-text {
        font-size: 2.5em;
    }

    .sub-text {
        font-size: 0.9em;
    }

    .landing-page {
        padding: 10px;
        height: auto;
    }
}

@media (max-width: 480px) {
    .main-text {
        font-size: 2em;
    }

    .sub-text {
        font-size: 0.8em;
    }

    .landing-page {
        padding: 10px;
        height: auto;
    }
}