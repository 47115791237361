.pricing-container {
    max-width: 968px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
   
    background: white;
    color: black;
   
    margin-bottom: 20px; /* Add margin bottom to space it from the other sections */
}

.pricing-heading {
    font-size: 2em;
    margin-bottom: 20px;
    width: 100%;
    color:#028382;
}

.pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.pricing-card {
    border: 1px solid black;
    padding: 20px;
    text-align: center;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.pricing-plan-name {
    font-size: 1.5em;
    margin: 10px 0;
}

.pricing-plan-price {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 10px;
}

.pricing-plan-features {
    list-style-type: disc; /* Ensures the list is an unordered list with bullets */
    padding-left: 20px; /* Adds padding to the left for better alignment */
    text-align: left;
}

.pricing-plan-features li {
    margin: 5px 0;
}

@media (max-width: 768px) {
    .pricing-card {
        padding: 15px;
    }

    .pricing-plan-name {
        font-size: 1.2em;
    }

    .pricing-plan-price {
        font-size: 1em;
    }

    .pricing-heading {
        font-size: 1.8em;
    }
}

@media (max-width: 480px) {
    .pricing-card {
        padding: 10px;
    }

    .pricing-plan-name {
        font-size: 1em;
    }

    .pricing-plan-price {
        font-size: 0.9em;
    }

    .pricing-heading {
        font-size: 1.5em;
    }
}
